
import {
  computed, defineComponent, PropType, onMounted,
} from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';

import * as LxpQuizTestService from '@/services/api/lxp-quiz-test';

import { PlayerSessionStatus, UUID } from '@/domains/common';
import { toQuizResult, toQuizView } from '@/domains/quiz';
import { IAtom } from '@/domains/atom';
import { IButtonsState } from '@/domains/assignment';

export default defineComponent({
  name: 'QuizWrapper',
  props: {
    track: {
      type: Object as PropType<IAtom>,
      required: true,
    },

    buttonsState: {
      type: Object as PropType<IButtonsState>,
      default: () => ({ prev: { enabled: false } }),
    },

    stepId: {
      type: String as PropType<UUID>,
      default: '',
    },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();

    const playerSessionId = computed(() => Number(route.params.playerSessionId));

    const fetchQuiz = async () => {
      try {
        const { playerSessionStatus } = await LxpQuizTestService.testInfoGet({
          playerSessionId: playerSessionId.value,
        });

        if ((playerSessionStatus === PlayerSessionStatus.CANCELED
          || playerSessionStatus === PlayerSessionStatus.COMPLETED)) {
          await router.replace(toQuizResult({
            playerSessionId: playerSessionId.value,
            stepId: props.stepId,
          }));
        } else {
          await router.replace(toQuizView({
            playerSessionId: playerSessionId.value,
            stepId: props.stepId,
          }));
        }
      } catch (e) {
        console.error(e);
      }
    };

    onMounted(async () => {
      await fetchQuiz();
    });
  },
});
